/**
 * vendor.css
 *
 * All vendor CSS is imported here.
 *
 */
@import "config";
@import "~swiper/src/swiper";
@import "~foundation-sites/scss/foundation";
@include foundation-everything();

/*! purgecss start ignore */
@import '~plyr/src/sass/plyr';
$aos-distance: 30px; // It has to be above import
@import '~aos/src/sass/aos';

/*! purgecss end ignore */
